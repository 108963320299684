export const SNOWPACK_PUBLIC_ENVIRONMENT = "development";
export const SNOWPACK_PUBLIC_API_URL = "https://pim-dev.crystallize.digital";
export const SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API = "https://dev-subscription-service.crystallize.com/api/graphql";
export const SNOWPACK_PUBLIC_PIM_VERSION = "ccd0dbce9f6d70e32cea96d675bb637e2acc6dab";
export const SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GENERATE_INVOICE_URL = "https://dev-subscription-service.crystallize.com/api/crystallize/generate-pdf";
export const SNOWPACK_PUBLIC_GOOGLE_CLOUD_API = "AIzaSyDpkkRla7AV5DLBhFrnAevJNGJLMDVZpA8";
export const SNOWPACK_PUBLIC_CLIENT_CATALOGUE_URL = "https://api-dev.crystallize.digital/:tenantIdentifier/catalogue";
export const SNOWPACK_PUBLIC_STRIPE_PUBLISHABLE_KEY = "pk_test_51IyI2yJ2kHvdWVjoxFoIeCBpgKTlBapKkwJloJSstFMJEqmjLTQc1uFpoYWaasK7SqJmLBIWY0IEP1NoUR1SQzgl00edViJtLj";
export const SNOWPACK_PUBLIC_CLIENT_SEARCH_URL = "https://api-dev.crystallize.digital/:tenantIdentifier/search";
export const MODE = "development";
export const NODE_ENV = "development";
export const SSR = false;